import React, { useState, ChangeEvent, FormEvent } from "react";
import { Card, Row, Col, Form, Button, Table } from "react-bootstrap";

interface InvoiceItem {
    account_id: string;
    description: string;
    quantity: string;
    price: string;
    subtotal: string;
}

const AddInvoiceForm: React.FC = () => {
    const [invoiceItems, setInvoiceItems] = useState<InvoiceItem[]>([
        { account_id: "", description: "", quantity: "", price: "", subtotal: "0.00" }
    ]);

    const [vatPercent, setVatPercent] = useState<number>(0);
    const [discountPercent, setDiscountPercent] = useState<number>(0);
    const [paidAmount, setPaidAmount] = useState<number>(0);

    const handleAddItem = () => {
        setInvoiceItems([...invoiceItems, { account_id: "", description: "", quantity: "", price: "", subtotal: "0.00" }]);
    };

    const handleRemoveItem = (index: number) => {
        const updatedItems = [...invoiceItems];
        updatedItems.splice(index, 1);
        setInvoiceItems(updatedItems);
    };

    const handleInputChange = (e: any, index: number) => {
        const { name, value } = e.target;
        const updatedItems = [...invoiceItems];
        updatedItems[index] = {
            ...updatedItems[index],
            [name]: value,
        };

        // Calculate subtotal based on quantity and price
        const quantity = parseFloat(updatedItems[index].quantity);
        const price = parseFloat(updatedItems[index].price);
        const subtotal = (quantity * price).toFixed(2); // Assuming subtotal should be formatted to 2 decimal places

        updatedItems[index] = {
            ...updatedItems[index],
            subtotal: subtotal.toString(),
        };

        setInvoiceItems(updatedItems);
    };

    const calculateTotal = (): number => {
        let total = 0;
        invoiceItems.forEach((item) => {
            total += parseFloat(item.subtotal);
        });
        return total;
    };

    const calculateVat = (): number => {
        return (calculateTotal() * (vatPercent / 100));
    };

    const calculateDiscount = (): number => {
        return (calculateTotal() * (discountPercent / 100));
    };

    const calculateGrandTotal = (): number => {
        return calculateTotal() + calculateVat() - calculateDiscount();
    };

    const calculateDue = (): number => {
        return calculateGrandTotal() - paidAmount;
    };

    const handleVatPercentChange = (e: ChangeEvent<HTMLInputElement>) => {
        const percent = parseInt(e.target.value);
        setVatPercent(percent);
    };

    const handleDiscountPercentChange = (e: ChangeEvent<HTMLInputElement>) => {
        const percent = parseInt(e.target.value);
        setDiscountPercent(percent);
    };

    const handlePaidAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
        const amount = parseInt(e.target.value);

        if (amount > calculateGrandTotal()) {
            setPaidAmount(calculateGrandTotal());
        } else {
            setPaidAmount(amount);
        }
    };

    return (
        <Card>
            <Card.Body>
                <div className="text-center mb-3">
                    <h3>Add Invoice</h3>
                    <h5>
                        Demo Hospital Limited
                        <br />
                        House#25, 4th Floor, Mannan Plaza, Khilkhet, Dhaka-1229, Bangladesh.
                    </h5>
                </div>
                <Form>

                    {/* Form inputs for patient details */}
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column md={2} className="text-end">
                            Patient ID
                        </Form.Label>
                        <Col md={4}>
                            <Form.Control
                                type="text"
                                name="patient_id"
                                id="patient_id"
                                className="invoice-input"
                                required
                            />
                            <Form.Text className="text-danger invlid_patient_id"></Form.Text>
                        </Col>
                        <Form.Label column md={2} className="text-end">
                            Full Name
                        </Form.Label>
                        <Col md={4}>
                            <Form.Control
                                type="text"
                                id="patient_name"
                                className="invoice-input"
                                required
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column md={2} className="text-end">
                            Address
                        </Form.Label>
                        <Col md={4}>
                            <Form.Control
                                type="text"
                                id="patient_address"
                                className="invoice-input"
                                required
                            />
                        </Col>
                        <Form.Label column md={2} className="text-end">
                            Date
                        </Form.Label>
                        <Col md={4}>
                            <Form.Control
                                type="date"
                                name="date"
                                className="datepicker invoice-input"
                                required
                            />
                        </Col>
                    </Form.Group>

                    <Table striped hover className="mb-3">
                        <thead className="bg-primary custom-table-header">
                            <tr>
                                <th>Account Name</th>
                                <th>Description</th>
                                <th>Quantity</th>
                                <th>Price</th>
                                <th>Sub Total</th>
                                <th>Add / Remove</th>
                            </tr>
                        </thead>
                        <tbody>
                            {invoiceItems.map((item, index) => (
                                <tr key={index}>
                                    <td>
                                        <Form.Select
                                            name="account_id"
                                            value={item.account_id}
                                            onChange={(e) => handleInputChange(e, index)}
                                            className="dont-select-me"
                                            required
                                        >
                                            <option value="" disabled>
                                                Select Option
                                            </option>
                                            <option value="20">Registration Fee</option>
                                            <option value="21">Doctor Visit</option>
                                            <option value="36">LAB</option>
                                            <option value="54">ELECTRICIAN</option>
                                            <option value="77">Baby Wipes</option>
                                            <option value="79">Quintessa Wall</option>
                                            <option value="82">Test Bill</option>
                                        </Form.Select>
                                    </td>
                                    <td>
                                        <Form.Control
                                            type="text"
                                            name="description"
                                            value={item.description}
                                            onChange={(e) => handleInputChange(e as ChangeEvent<HTMLInputElement>, index)}
                                            className="form-control"
                                            placeholder="Description"
                                        />
                                    </td>
                                    <td>
                                        <Form.Control
                                            type="number"
                                            name="quantity"
                                            value={item.quantity}
                                            onChange={(e) => handleInputChange(e as ChangeEvent<HTMLInputElement>, index)}
                                            required
                                            autoComplete="off"
                                            className="totalCal form-control"
                                            placeholder="Quantity"
                                        />
                                    </td>
                                    <td>
                                        <Form.Control
                                            type="number"
                                            name="price"
                                            value={item.price}
                                            onChange={(e) => handleInputChange(e as ChangeEvent<HTMLInputElement>, index)}
                                            required
                                            autoComplete="off"
                                            className="totalCal form-control"
                                            placeholder="Price"
                                        />
                                    </td>
                                    <td>
                                        <Form.Control
                                            type="number"
                                            name="subtotal"
                                            value={item.subtotal}
                                            readOnly
                                            autoComplete="off"
                                            className="subtotal form-control"
                                            placeholder="Sub Total"
                                        />
                                    </td>
                                    <td>
                                        {index === invoiceItems.length - 1 && (
                                            <Button
                                                type="button"
                                                variant="primary"
                                                className="btn-sm"
                                                onClick={handleAddItem}
                                            >
                                                Add
                                            </Button>
                                        )}
                                        {index !== invoiceItems.length - 1 && (
                                            <Button
                                                type="button"
                                                variant="danger"
                                                className="btn-sm"
                                                onClick={() => handleRemoveItem(index)}
                                            >
                                                Remove
                                            </Button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                        {/* Footer section for total, vat, discount, grand total, etc. */}
                        <tfoot>
                            <tr className="bg-info">
                                <td colSpan={3}></td>
                                <th className="text-end">Total</th>
                                <td>
                                    <Form.Control
                                        type="number"
                                        name="total"
                                        id="total"
                                        className="form-control"
                                        readOnly
                                        required
                                        placeholder="Total"
                                        value={calculateTotal().toFixed(2)}
                                    />
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <th colSpan={3} className="text-end">
                                    Vat (%)
                                </th>
                                <td colSpan={2}>
                                    <Form.Control
                                        type="number"
                                        id="vatPercent"
                                        name="vatPercent"
                                        required
                                        autoComplete="off"
                                        className="form-control"
                                        defaultValue={vatPercent.toString()}
                                        onChange={handleVatPercentChange}
                                    />
                                </td>
                                <td>
                                    <Form.Control
                                        type="number"
                                        name="vat"
                                        id="vat"
                                        required
                                        autoComplete="off"
                                        className="vatDiscount paidDue form-control"
                                        placeholder="Vat"
                                        value={calculateVat().toFixed(2)}
                                        readOnly
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th colSpan={3} className="text-end">
                                    Discount (%)
                                </th>
                                <td colSpan={2}>
                                    <Form.Control
                                        type="number"
                                        id="discountPercent"
                                        required
                                        autoComplete="off"
                                        className="form-control"
                                        defaultValue={discountPercent.toString()}
                                        onChange={handleDiscountPercentChange}
                                    />
                                </td>
                                <td>
                                    <Form.Control
                                        type="number"
                                        name="discount"
                                        required
                                        autoComplete="off"
                                        id="discount"
                                        className="vatDiscount paidDue form-control"
                                        placeholder="Discount"
                                        value={calculateDiscount().toFixed(2)}
                                        readOnly
                                    />
                                </td>
                            </tr>
                            <tr className="bg-success">
                                <td colSpan={3}></td>
                                <th className="text-end">Grand Total</th>
                                <td>
                                    <Form.Control
                                        type="number"
                                        name="grand_total"
                                        readOnly
                                        required
                                        autoComplete="off"
                                        id="grand_total"
                                        className="paidDue form-control"
                                        placeholder="Grand Total"
                                        value={calculateGrandTotal().toFixed(2)}
                                    />
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td colSpan={3}></td>
                                <th className="text-end">Paid</th>
                                <td>
                                <Form.Control
                                        type="number"
                                        name="paid"
                                        id="paid"
                                        autoComplete="off"
                                        className="paidDue form-control"
                                        required
                                        placeholder="Paid"
                                        value={paidAmount.toString()}
                                        onChange={handlePaidAmountChange}
                                    />
                                </td>
                                <td></td>
                            </tr>
                            <tr className="bg-danger">
                                <td colSpan={3}></td>
                                <th className="text-end">Due</th>
                                <td>
                                    <Form.Control
                                        type="number"
                                        name="due"
                                        id="due"
                                        autoComplete="off"
                                        className="paidDue form-control"
                                        required
                                        placeholder="Due"
                                        value={calculateDue().toFixed(2)}
                                        readOnly
                                    />
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td colSpan={3}>
                                    <Form.Group as={Row}>
                                        <Form.Label column xs={3}>
                                            Status
                                        </Form.Label>
                                        <Col xs={9}>
                                            <Form.Check
                                                inline
                                                label="Active"
                                                type="radio"
                                                name="status"
                                                id="status_active"
                                                value="1"
                                                defaultChecked
                                            />
                                            <Form.Check
                                                inline
                                                label="Inactive"
                                                type="radio"
                                                name="status"
                                                id="status_inactive"
                                                value="0"
                                            />
                                        </Col>
                                    </Form.Group>
                                </td>
                                <td>
                                    <Button type="reset" variant="info" className="btn-block">
                                        Reset
                                    </Button>
                                </td>
                                <td>
                                    <Button type="submit" variant="success" className="btn-block">
                                        Save
                                    </Button>
                                </td>
                                <td></td>
                            </tr>
                        </tfoot>
                    </Table>
                </Form>
            </Card.Body>
        </Card>
    );
};

export default AddInvoiceForm;
