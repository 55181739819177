import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import PageTitle from "../../PageTitle";

const AddPayment = () => {
  const [currentDate, setCurrentDate] = useState('');

  const getCurrentDate = () => {
    const date = new Date().toISOString().split('T')[0];
    setCurrentDate(date);
  };

  useEffect(() => {
    getCurrentDate();
  }, []);

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Tables", path: "/features/tables/advanced" },
          {
            label: "Advanced Tables",
            path: "/features/tables/advanced",
            active: true,
          },
        ]}
        title={"Add Payment"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <h4 className="mb-3 header-title mt-0">Add Payment</h4>

              <Form>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={2} htmlFor="date">
                    Date
                  </Form.Label>
                  <Col lg={7}>
                    <Form.Control id="date" type="date" name="date" value={currentDate} />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={2} htmlFor="accountName">
                    Account Name
                  </Form.Label>
                  <Col lg={7}>
                    <Form.Select id="accountName" name="accountName">
                      <option value="">Select Account</option>
                      <option value="account1">Account 1</option>
                      <option value="account2">Account 2</option>
                      <option value="account3">Account 3</option>
                    </Form.Select>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={2} htmlFor="payTo">
                    Pay To
                  </Form.Label>
                  <Col lg={7}>
                    <Form.Control
                      id="payTo"
                      name="payTo"
                      placeholder="Pay To"
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={2} htmlFor="type">
                    Type
                  </Form.Label>
                  <Col lg={7}>
                    <Form.Select id="type" name="type">
                      <option value="debit">Debit</option>
                      <option value="credit">Credit</option>
                    </Form.Select>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={2} htmlFor="description">
                    Description
                  </Form.Label>
                  <Col lg={7}>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      id="description"
                      name="description"
                      placeholder="Description"
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={2} htmlFor="amount">
                    Amount
                  </Form.Label>
                  <Col lg={7}>
                    <Form.Control id="amount" type="number" name="amount" />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={2}>
                    Status
                  </Form.Label>
                  <Col lg={7}>
                    <Row>
                      <Col md={"auto"}>
                        <Form.Check
                          type="radio"
                          className="mb-1"
                          id="active"
                          name="status"
                          label="Active"
                          defaultChecked
                        />
                      </Col>
                      <Col md={"auto"}>
                        <Form.Check
                          type="radio"
                          className="mb-1"
                          id="inactive"
                          name="status"
                          label="Inactive"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Form.Group>

                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>

    </>
  );
};

export default AddPayment;

