import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import PageTitle from "../../PageTitle";
import { Plus, Minus } from 'feather-icons-react';

const AddPurchases = () => {
  // Define the types for product and payment rows
  interface ProductRow {
    id: number;
    product: string;
    unitPrice: number;
    quantity: number;
    totalPrice: number;
  }

  interface PaymentRow {
    id: number;
    date: string;
    amount: number;
    method: string;
  }

  // State variables
  const [productRows, setProductRows] = useState<ProductRow[]>([{ id: 1, product: "", unitPrice: 0, quantity: 0, totalPrice: 0 }]);
  const [paymentRows, setPaymentRows] = useState<PaymentRow[]>([{ id: 1, date: "", amount: 0, method: "" }]);
  const [subtotal, setSubtotal] = useState(0);
  const [tax, setTax] = useState(0);
  const [total, setTotal] = useState(0);
  const [paid, setPaid] = useState(0);
  const [due, setDue] = useState(0);

  // Handle adding a new product row
  const handleAddProduct = () => {
    const newRowId = productRows.length + 1;
    setProductRows([...productRows, { id: newRowId, product: "", unitPrice: 0, quantity: 0, totalPrice: 0 }]);
  };

  // Handle removing a product row
  const handleRemoveProduct = (idToRemove: number) => {
    const updatedRows = productRows.filter((row) => row.id !== idToRemove);
    setProductRows(updatedRows);
  };

  // Handle changes in product row inputs
  const handleProductChange = (id: number, field: keyof ProductRow, value: any) => {
    const updatedRows = productRows.map((row) => {
      if (row.id === id) {
        const newRow = { ...row, [field]: value };
        if (field === "unitPrice" || field === "quantity") {
          newRow.totalPrice = (newRow.unitPrice * newRow.quantity) || 0;
        }
        return newRow;
      }
      return row;
    });
    setProductRows(updatedRows);
  };

  // Handle adding a new payment row
  const handleAddPayment = () => {
    const newRowId = paymentRows.length + 1;
    setPaymentRows([...paymentRows, { id: newRowId, date: "", amount: 0, method: "" }]);
  };

  // Handle removing a payment row
  const handleRemovePayment = (idToRemove: number) => {
    const updatedRows = paymentRows.filter((row) => row.id !== idToRemove);
    setPaymentRows(updatedRows);
  };

  // Handle changes in payment row inputs
  const handlePaymentChange = (id: number, field: keyof PaymentRow, value: any) => {
    const updatedRows = paymentRows.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: value };
      }
      return row;
    });
    setPaymentRows(updatedRows);
  };

  // Calculate the summary details
  useEffect(() => {
    // Calculate subtotal
    const calculatedSubtotal = productRows.reduce((sum, row) => sum + (row.totalPrice || 0), 0);
    setSubtotal(calculatedSubtotal);

    // Assuming tax is 10% of subtotal for this example
    const calculatedTax = calculatedSubtotal * 0.10;
    setTax(calculatedTax);

    // Calculate total
    const calculatedTotal = calculatedSubtotal + calculatedTax;
    setTotal(calculatedTotal);

    // Calculate paid amount
    const calculatedPaid = paymentRows.reduce((sum, row) => sum + (row.amount || 0), 0);
    setPaid(calculatedPaid);

    // Calculate due amount
    const calculatedDue = calculatedTotal - calculatedPaid;
    setDue(calculatedDue);
  }, [productRows, paymentRows]);

  // Handle form submission
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("Form submitted:", productRows, paymentRows, { subtotal, tax, total, paid, due });
    // Add your submit logic here
  };

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Tables", path: "/features/tables/advanced" },
          {
            label: "Advanced Tables",
            path: "/features/tables/advanced",
            active: true,
          },
        ]}
        title={"Add Purchases"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <h4 className="mb-3 header-title mt-0">Add Purchases</h4>

              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={3} className="mb-3">
                    <Form.Control type="date" placeholder="Date" />
                  </Col>
                  <Col md={3} className="mb-3">
                    <Form.Control as="select" placeholder="Branch">
                      <option>Branch 1</option>
                      <option>Branch 2</option>
                      <option>Branch 3</option>
                    </Form.Control>
                  </Col>
                  <Col md={3} className="mb-3">
                    <Form.Control as="select" placeholder="Supplier">
                      <option>Supplier 1</option>
                      <option>Supplier 2</option>
                      <option>Supplier 3</option>
                    </Form.Control>
                  </Col>
                  <Col md={3} className="mb-3">
                    <Form.Control as="textarea" placeholder="Note" />
                  </Col>
                </Row>

                {/* Products Table */}
                <Row>
                  <Col>
                    <Card className="card-primary card-outline">
                      <Card.Header>
                        <Row className="mb-1">
                          <Col className="d-flex justify-content-between align-items-center">
                            <h5 className="mb-0 header-title mt-0">Products</h5>
                            <Button
                              type="button"
                              className="btn btn-primary add_product"
                              onClick={handleAddProduct}
                            >
                              <Plus />
                            </Button>
                          </Col>
                        </Row>
                      </Card.Header>
                      <Card.Body className="p-0">
                        <table className="table table-bordered" id="products_table">
                          <thead>
                            <tr>
                              <th>Product</th>
                              <th>Unit price</th>
                              <th>Quantity</th>
                              <th>Total price</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {productRows.map((row) => (
                              <tr key={row.id}>
                                <td>
                                  <Form.Control as="select" value={row.product} onChange={(e) => handleProductChange(row.id, 'product', e.target.value)}>
                                    <option value="">Select Product</option>
                                    <option value="Product 1">Product 1</option>
                                    <option value="Product 2">Product 2</option>
                                    <option value="Product 3">Product 3</option>
                                  </Form.Control>
                                </td>
                                <td>
                                  <Form.Control type="number" value={row.unitPrice} placeholder="Unit Price" onChange={(e) => handleProductChange(row.id, 'unitPrice', parseFloat(e.target.value))} />
                                </td>
                                <td>
                                  <Form.Control type="number" value={row.quantity} placeholder="Quantity" onChange={(e) => handleProductChange(row.id, 'quantity', parseInt(e.target.value))} />
                                </td>
                                <td>
                                  <Form.Control type="number" value={row.totalPrice} placeholder="Total Price" readOnly />
                                </td>
                                <td>
                                  <Button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => handleRemoveProduct(row.id)}
                                  >
                                    <Minus />
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

                {/* Payments Table */}
                <Row>
                  <Col>
                    <Card className="card-primary card-outline">
                      <Card.Header>
                        <Row className="mb-1">
                          <Col className="d-flex justify-content-between align-items-center">
                            <h5 className="mb-0 header-title mt-0">Payments</h5>
                            <Button
                              type="button"
                              className="btn btn-primary add_payment"
                              onClick={handleAddPayment}
                            >
                              <Plus />
                            </Button>
                          </Col>
                        </Row>
                      </Card.Header>
                      <Card.Body className="p-0">
                        <table className="table table-bordered" id="payments_table">
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Amount</th>
                              <th>Payment method</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {paymentRows.map((row) => (
                              <tr key={row.id}>
                                <td>
                                  <Form.Control type="date" value={row.date} placeholder="Date" onChange={(e) => handlePaymentChange(row.id, 'date', e.target.value)} />
                                </td>
                                <td>
                                  <Form.Control type="number" value={row.amount} placeholder="Amount" onChange={(e) => handlePaymentChange(row.id, 'amount', parseFloat(e.target.value))} />
                                </td>
                                <td>
                                  <Form.Control as="select" value={row.method} placeholder="Payment Method" onChange={(e) => handlePaymentChange(row.id, 'method', e.target.value)}>
                                    <option value="">Select Method</option>
                                    <option value="Cash">Cash</option>
                                    <option value="Credit Card">Credit Card</option>
                                    <option value="Bank Transfer">Bank Transfer</option>
                                  </Form.Control>
                                </td>
                                <td>
                                  <Button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => handleRemovePayment(row.id)}
                                  >
                                    <Minus />
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

                {/* Purchase Summary */}
                <Row>
                  <Col>
                    <Card className="card-primary">
                      <Card.Header>
                      <h5 className="mb-0 header-title mt-0">Product Summary</h5>
                      </Card.Header>
                      <Card.Body className="p-0">
                        <table className="table table-bordered">
                          <tbody>
                            <tr>
                              <th>Subtotal</th>
                              <td>
                                <Form.Control type="number" value={subtotal.toFixed(2)} placeholder="Subtotal" readOnly />
                              </td>
                            </tr>
                            <tr>
                              <th>Tax</th>
                              <td>
                                <Form.Control type="number" value={tax.toFixed(2)} placeholder="Tax" onChange={(e) => setTax(parseFloat(e.target.value))} />
                              </td>
                            </tr>
                            <tr>
                              <th>Total</th>
                              <td>
                                <Form.Control type="number" value={total.toFixed(2)} placeholder="Total" readOnly />
                              </td>
                            </tr>
                            <tr>
                              <th>Paid</th>
                              <td>
                                <Form.Control type="number" value={paid.toFixed(2)} placeholder="Paid" onChange={(e) => setPaid(parseFloat(e.target.value))} />
                              </td>
                            </tr>
                            <tr>
                              <th>Due</th>
                              <td>
                                <Form.Control type="number" value={due.toFixed(2)} placeholder="Due" readOnly />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

                <Button variant="primary" type="submit" className="mt-3">
                  Submit
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AddPurchases;
