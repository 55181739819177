import React from "react";
import { Row, Col, Card, Dropdown, ButtonGroup } from "react-bootstrap";
import { Cell, Row as TableRow } from 'react-table'; // Import Cell and Row types
import { payments as data } from "../data";
import { Link } from "react-router-dom";
import { Edit, Trash } from 'feather-icons-react';
import PageTitle from "../../PageTitle";
import Table from "../../Table";

const columns = [
  {
    Header: "ID",
    accessor: "id",
    sort: true
  },
  {
    Header: "Date",
    accessor: "date",
    sort: true 
  },
  {
    Header: "Account Name",
    accessor: "account_name",
    sort: true
  },
  {
    Header: "Pay To",
    accessor: "pay_to",
    sort: true 
  },
  {
    Header: "Description",
    accessor: "description",
    sort: false 
  },
  {
    Header: "Amount",
    accessor: "amount",
    sort: true 
  },
  {
    Header: "Status",
    accessor: "status",
    sort: true
  },
  {
    Header: "Action", accessor: "action", sort: false, Cell: ({ row }: { row: TableRow<any> }) => (
      <Dropdown>
        <Dropdown.Toggle variant="primary" id="dropdown-action" style={{ borderRadius: "50%", width: "30px", height: "30px", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <i className="bi-gear-wide-connected"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item as={Link} to={`/lab/edit-invoice/${row.original.id}`}><Edit /> Edit</Dropdown.Item>
          <Dropdown.Item><Trash />Delete</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    ),
  },
];

const sizePerPageList = [
  {
    text: "5",
    value: 5,
  },
  {
    text: "10",
    value: 10,
  },
  {
    text: "25",
    value: 25,
  },
  {
    text: "All",
    value: data.length,
  },
];

const PaymentList = () => {
  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Tables", path: "/features/tables/advanced" },
          {
            label: "Advanced Tables",
            path: "/features/tables/advanced",
            active: true,
          },
        ]}
        title={"Invoice List"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <div className="page-title-box">
                <h4 className="page-title">
                  Payment List
                </h4>
                <div className="page-title-right">
                  <ButtonGroup className="ms-2 d-none d-sm-inline-block me-1">
                    <Link to="/accounts/payments/create" className="btn btn-primary">
                      <i className="uil uil-plus me-1"></i>Add Payment</Link>
                  </ButtonGroup>
                </div>
              </div>

              <Table
                columns={columns}
                data={data}
                pageSize={5}
                sizePerPageList={sizePerPageList}
                isSortable={true}
                pagination={true}
                isSearchable={true}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

    </>
  );
};

export default PaymentList;
