interface Records {
  id: number;
  age: number;
  name: string;
  company: string;
  phone: string;
}


interface ExpandableRecords {
  id: number;
  age: number;
  name: string;
  company: string;
  phone: string;
  subRows: Records[];
}

const records: Records[] = [
  {
    id: 1,
    age: 32,
    name: "Burt",
    company: "Kaggle",
    phone: "+1 (823) 532-2427",
  },
  {
    id: 2,
    age: 23,
    name: "Bruno",
    company: "Magmina",
    phone: "+1 (813) 583-2089",
  },
  {
    id: 3,
    age: 31,
    name: "Alvarado",
    company: "Translink",
    phone: "+1 (975) 468-3875",
  },
  {
    id: 4,
    age: 24,
    name: "Lilia",
    company: "Digitalus",
    phone: "+1 (891) 537-3461",
  },
  {
    id: 5,
    age: 25,
    name: "Amanda",
    company: "Bunga",
    phone: "+1 (916) 522-3747",
  },
  {
    id: 6,
    age: 20,
    name: "Alexandra",
    company: "Conjurica",
    phone: "+1 (876) 492-3181",
  },
  {
    id: 7,
    age: 27,
    name: "Diana",
    company: "Extragen",
    phone: "+1 (977) 417-3038",
  },
  {
    id: 8,
    age: 26,
    name: "Goodman",
    company: "Aquamate",
    phone: "+1 (930) 545-2289",
  },
  {
    id: 9,
    age: 26,
    name: "Edith",
    company: "Pyrami",
    phone: "+1 (854) 506-3468",
  },
  {
    id: 10,
    age: 29,
    name: "Juana",
    company: "Singavera",
    phone: "+1 (872) 560-2324",
  },
  {
    id: 11,
    age: 21,
    name: "Fitzgerald",
    company: "Dancerity",
    phone: "+1 (813) 573-2507",
  },
  {
    id: 12,
    age: 38,
    name: "Madden",
    company: "Corpulse",
    phone: "+1 (935) 534-3876",
  },
  {
    id: 13,
    age: 40,
    name: "Jewell",
    company: "Frenex",
    phone: "+1 (886) 516-3262",
  },
  {
    id: 14,
    age: 32,
    name: "Kerr",
    company: "Artiq",
    phone: "+1 (807) 561-3077",
  },
  {
    id: 15,
    age: 20,
    name: "Washington",
    company: "Organica",
    phone: "+1 (948) 458-3517",
  },
  {
    id: 16,
    age: 20,
    name: "Audrey",
    company: "Softmicro",
    phone: "+1 (900) 592-3841",
  },
  {
    id: 17,
    age: 39,
    name: "Allison",
    company: "Playce",
    phone: "+1 (998) 478-3810",
  },
  {
    id: 18,
    age: 25,
    name: "Holder",
    company: "Paragonia",
    phone: "+1 (850) 536-2708",
  },
  {
    id: 19,
    age: 26,
    name: "Atkinson",
    company: "Scentric",
    phone: "+1 (850) 467-2761",
  },
  {
    id: 20,
    age: 35,
    name: "Delaney",
    company: "Telpod",
    phone: "+1 (934) 468-2218",
  },
  {
    id: 21,
    age: 20,
    name: "Myrna",
    company: "Zanity",
    phone: "+1 (953) 565-3836",
  },
  {
    id: 22,
    age: 30,
    name: "Erna",
    company: "Techade",
    phone: "+1 (872) 574-3879",
  },
  {
    id: 23,
    age: 36,
    name: "Fannie",
    company: "Cubix",
    phone: "+1 (843) 576-2904",
  },
  {
    id: 24,
    age: 38,
    name: "Melody",
    company: "Talae",
    phone: "+1 (817) 424-3500",
  },
  {
    id: 25,
    age: 27,
    name: "Letitia",
    company: "Enjola",
    phone: "+1 (857) 441-2917",
  },
  {
    id: 26,
    age: 33,
    name: "Nina",
    company: "Eventex",
    phone: "+1 (917) 599-2793",
  },
  {
    id: 27,
    age: 40,
    name: "Byrd",
    company: "Obones",
    phone: "+1 (846) 422-2064",
  },
  {
    id: 28,
    age: 34,
    name: "Chen",
    company: "Dadabase",
    phone: "+1 (956) 474-3409",
  },
  {
    id: 29,
    age: 25,
    name: "Alexandria",
    company: "Turnabout",
    phone: "+1 (964) 415-2278",
  },
  {
    id: 30,
    age: 37,
    name: "Page",
    company: "Metroz",
    phone: "+1 (897) 541-2460",
  },
  {
    id: 31,
    age: 24,
    name: "Clare",
    company: "Zilch",
    phone: "+1 (832) 591-3814",
  },
  {
    id: 32,
    age: 38,
    name: "Lindsey",
    company: "Roughies",
    phone: "+1 (942) 579-2920",
  },
  {
    id: 33,
    age: 32,
    name: "Oconnor",
    company: "Kinetica",
    phone: "+1 (899) 599-3206",
  },
  {
    id: 34,
    age: 35,
    name: "Maldonado",
    company: "Zentime",
    phone: "+1 (955) 419-2815",
  },
  {
    id: 35,
    age: 25,
    name: "Day",
    company: "Eargo",
    phone: "+1 (895) 555-2916",
  },
  {
    id: 36,
    age: 20,
    name: "Collier",
    company: "Phuel",
    phone: "+1 (998) 468-2079",
  },
  {
    id: 37,
    age: 40,
    name: "Jeannette",
    company: "Entogrok",
    phone: "+1 (904) 567-2078",
  },
  {
    id: 38,
    age: 33,
    name: "Wallace",
    company: "Nurali",
    phone: "+1 (877) 566-3957",
  },
  {
    id: 39,
    age: 39,
    name: "Mcfadden",
    company: "Cincyr",
    phone: "+1 (949) 405-3992",
  },
  {
    id: 40,
    age: 21,
    name: "Chrystal",
    company: "Futurize",
    phone: "+1 (988) 458-3032",
  },
  {
    id: 41,
    age: 31,
    name: "Leila",
    company: "Zensure",
    phone: "+1 (902) 447-2419",
  },
  {
    id: 42,
    age: 24,
    name: "Madelyn",
    company: "Egypto",
    phone: "+1 (881) 538-3081",
  },
  {
    id: 43,
    age: 39,
    name: "Peck",
    company: "Tellifly",
    phone: "+1 (803) 452-3922",
  },
  {
    id: 44,
    age: 32,
    name: "Garrett",
    company: "Aquasure",
    phone: "+1 (876) 475-2185",
  },
  {
    id: 45,
    age: 21,
    name: "Kramer",
    company: "Terrago",
    phone: "+1 (912) 404-2597",
  },
  {
    id: 46,
    age: 35,
    name: "Lane",
    company: "Anivet",
    phone: "+1 (911) 495-3587",
  },
  {
    id: 47,
    age: 21,
    name: "Merritt",
    company: "Inear",
    phone: "+1 (856) 519-3838",
  },
  {
    id: 48,
    age: 25,
    name: "Margarita",
    company: "Unq",
    phone: "+1 (931) 558-3156",
  },
  {
    id: 49,
    age: 28,
    name: "Leigh",
    company: "Marqet",
    phone: "+1 (918) 526-2007",
  },
  {
    id: 50,
    age: 31,
    name: "Coleman",
    company: "Insuresys",
    phone: "+1 (827) 449-3786",
  },
  {
    id: 51,
    age: 31,
    name: "Alexander",
    company: "Portico",
    phone: "+1 (854) 576-2455",
  },
  {
    id: 52,
    age: 38,
    name: "Tanisha",
    company: "Earthwax",
    phone: "+1 (994) 494-3496",
  },
  {
    id: 53,
    age: 23,
    name: "Crane",
    company: "Pushcart",
    phone: "+1 (924) 497-3347",
  },
  {
    id: 54,
    age: 26,
    name: "Carmella",
    company: "Acusage",
    phone: "+1 (898) 575-2585",
  },
  {
    id: 55,
    age: 27,
    name: "Rosalind",
    company: "Isologica",
    phone: "+1 (838) 572-2994",
  },
  {
    id: 56,
    age: 37,
    name: "Duran",
    company: "Gazak",
    phone: "+1 (991) 446-3820",
  },
  {
    id: 57,
    age: 27,
    name: "Bernard",
    company: "Zoinage",
    phone: "+1 (824) 585-2197",
  },
  {
    id: 58,
    age: 29,
    name: "Tate",
    company: "Endipine",
    phone: "+1 (896) 448-2084",
  },
  {
    id: 59,
    age: 39,
    name: "Pearlie",
    company: "Progenex",
    phone: "+1 (805) 545-2585",
  },
  {
    id: 60,
    age: 20,
    name: "Manning",
    company: "Handshake",
    phone: "+1 (917) 405-3406",
  },
];

interface Accounts {
  id: number;
  account_name: string;
  description: string;
  type: string;
  date: string;
  status: string;
}

interface Accounts {
  id: number;
  account_name: string;
  description: string;
  type: string;
  date: string;
  status: string;
}

const accountsArray: Accounts[] = [
  { id: 1, account_name: "Lab Equipment Purchase", description: "Debit for lab equipment", type: "Debit", date: "2024-01-15", status: "Completed" },
  { id: 2, account_name: "Lab Supplies Purchase", description: "Debit for lab supplies", type: "Debit", date: "2024-01-20", status: "Completed" },
  { id: 3, account_name: "Salary Payment", description: "Credit for lab staff salary", type: "Credit", date: "2024-01-25", status: "Completed" },
  { id: 4, account_name: "Utility Bill Payment", description: "Debit for utility bills", type: "Debit", date: "2024-02-01", status: "Pending" },
  { id: 5, account_name: "Grant Fund Received", description: "Credit for received grant funds", type: "Credit", date: "2024-02-05", status: "Completed" },
  { id: 6, account_name: "Maintenance Expense", description: "Debit for lab maintenance", type: "Debit", date: "2024-02-10", status: "Completed" },
  { id: 7, account_name: "Consulting Fees", description: "Credit for consulting services", type: "Credit", date: "2024-02-15", status: "Completed" },
  { id: 8, account_name: "Insurance Premium", description: "Debit for insurance payment", type: "Debit", date: "2024-02-20", status: "Pending" },
  { id: 9, account_name: "Research Funding", description: "Credit for research funding", type: "Credit", date: "2024-02-25", status: "Completed" },
  { id: 10, account_name: "Lab Renovation", description: "Debit for lab renovation", type: "Debit", date: "2024-03-01", status: "Pending" },
  { id: 11, account_name: "Miscellaneous Expense", description: "Debit for miscellaneous expenses", type: "Debit", date: "2024-03-05", status: "Completed" },
  { id: 12, account_name: "Equipment Sale", description: "Credit from equipment sale", type: "Credit", date: "2024-03-10", status: "Completed" },
  { id: 13, account_name: "Workshop Income", description: "Credit from workshop", type: "Credit", date: "2024-03-15", status: "Completed" },
  { id: 14, account_name: "Software Purchase", description: "Debit for software licenses", type: "Debit", date: "2024-03-20", status: "Pending" },
  { id: 15, account_name: "Cleaning Service", description: "Debit for cleaning services", type: "Debit", date: "2024-03-25", status: "Completed" },
  { id: 16, account_name: "Donations Received", description: "Credit from donations", type: "Credit", date: "2024-03-30", status: "Completed" },
  { id: 17, account_name: "Conference Fee", description: "Debit for conference fees", type: "Debit", date: "2024-04-01", status: "Pending" },
  { id: 18, account_name: "Subscription Fee", description: "Debit for journal subscriptions", type: "Debit", date: "2024-04-05", status: "Completed" },
  { id: 19, account_name: "Travel Expense", description: "Debit for travel expenses", type: "Debit", date: "2024-04-10", status: "Pending" },
  { id: 20, account_name: "Student Fees", description: "Credit from student fees", type: "Credit", date: "2024-04-15", status: "Completed" },
  { id: 21, account_name: "Lab Expansion", description: "Debit for lab expansion", type: "Debit", date: "2024-04-20", status: "Pending" },
  { id: 22, account_name: "Project Funding", description: "Credit for project funding", type: "Credit", date: "2024-04-25", status: "Completed" },
  { id: 23, account_name: "Lab Inventory", description: "Debit for lab inventory restock", type: "Debit", date: "2024-04-30", status: "Pending" },
  { id: 24, account_name: "Miscellaneous Income", description: "Credit for miscellaneous income", type: "Credit", date: "2024-05-05", status: "Completed" },
  { id: 25, account_name: "Research Grant", description: "Credit for research grant", type: "Credit", date: "2024-05-10", status: "Completed" },
];


const expandableRecords: ExpandableRecords[] = [
  {
    id: 1,
    age: 32,
    name: "Burt",
    company: "Kaggle",
    phone: "+1 (823) 532-2427",
    subRows: [
      {
        id: 51,
        age: 23,
        name: "Bruno",
        company: "Magmina",
        phone: "+1 (813) 583-2089",
      },
    ],
  },
  {
    id: 2,
    age: 23,
    name: "Long",
    company: "Magmina",
    phone: "+1 (813) 583-2089",
    subRows: [
      {
        id: 51,
        age: 23,
        name: "Bruno",
        company: "Magmina",
        phone: "+1 (813) 583-2089",
      },
    ],
  },
  {
    id: 3,
    age: 31,
    name: "Alvarado",
    company: "Translink",
    phone: "+1 (975) 468-3875",
    subRows: [
      {
        id: 51,
        age: 23,
        name: "Bruno",
        company: "Magmina",
        phone: "+1 (813) 583-2089",
      },
    ],
  },
  {
    id: 4,
    age: 24,
    name: "Lilia",
    company: "Digitalus",
    phone: "+1 (891) 537-3461",
    subRows: [
      {
        id: 51,
        age: 23,
        name: "Bruno",
        company: "Magmina",
        phone: "+1 (813) 583-2089",
      },
    ],
  },
  {
    id: 5,
    age: 25,
    name: "Amanda",
    company: "Bunga",
    phone: "+1 (916) 522-3747",
    subRows: [
      {
        id: 51,
        age: 23,
        name: "Bruno",
        company: "Magmina",
        phone: "+1 (813) 583-2089",
      },
    ],
  },
  {
    id: 6,
    age: 20,
    name: "Alexandra",
    company: "Conjurica",
    phone: "+1 (876) 492-3181",
    subRows: [
      {
        id: 51,
        age: 23,
        name: "Bruno",
        company: "Magmina",
        phone: "+1 (813) 583-2089",
      },
    ],
  },
  {
    id: 7,
    age: 27,
    name: "Diana",
    company: "Extragen",
    phone: "+1 (977) 417-3038",
    subRows: [
      {
        id: 51,
        age: 23,
        name: "Bruno",
        company: "Magmina",
        phone: "+1 (813) 583-2089",
      },
    ],
  },
  {
    id: 8,
    age: 26,
    name: "Goodman",
    company: "Aquamate",
    phone: "+1 (930) 545-2289",
    subRows: [
      {
        id: 51,
        age: 23,
        name: "Bruno",
        company: "Magmina",
        phone: "+1 (813) 583-2089",
      },
    ],
  },
  {
    id: 9,
    age: 26,
    name: "Edith",
    company: "Pyrami",
    phone: "+1 (854) 506-3468",
    subRows: [
      {
        id: 51,
        age: 23,
        name: "Bruno",
        company: "Magmina",
        phone: "+1 (813) 583-2089",
      },
    ],
  },
  {
    id: 10,
    age: 29,
    name: "Juana",
    company: "Singavera",
    phone: "+1 (872) 560-2324",
    subRows: [
      {
        id: 51,
        age: 23,
        name: "Bruno",
        company: "Magmina",
        phone: "+1 (813) 583-2089",
      },
    ],
  },
  {
    id: 11,
    age: 21,
    name: "Fitzgerald",
    company: "Dancerity",
    phone: "+1 (813) 573-2507",
    subRows: [
      {
        id: 51,
        age: 23,
        name: "Bruno",
        company: "Magmina",
        phone: "+1 (813) 583-2089",
      },
    ],
  },
  {
    id: 12,
    age: 38,
    name: "Madden",
    company: "Corpulse",
    phone: "+1 (935) 534-3876",
    subRows: [
      {
        id: 51,
        age: 23,
        name: "Bruno",
        company: "Magmina",
        phone: "+1 (813) 583-2089",
      },
    ],
  },
  {
    id: 13,
    age: 40,
    name: "Jewell",
    company: "Frenex",
    phone: "+1 (886) 516-3262",
    subRows: [
      {
        id: 51,
        age: 23,
        name: "Bruno",
        company: "Magmina",
        phone: "+1 (813) 583-2089",
      },
    ],
  },
  {
    id: 14,
    age: 32,
    name: "Kerr",
    company: "Artiq",
    phone: "+1 (807) 561-3077",
    subRows: [
      {
        id: 51,
        age: 23,
        name: "Bruno",
        company: "Magmina",
        phone: "+1 (813) 583-2089",
      },
    ],
  },
  {
    id: 15,
    age: 20,
    name: "Washington",
    company: "Organica",
    phone: "+1 (948) 458-3517",
    subRows: [
      {
        id: 51,
        age: 23,
        name: "Bruno",
        company: "Magmina",
        phone: "+1 (813) 583-2089",
      },
    ],
  },
  {
    id: 16,
    age: 20,
    name: "Audrey",
    company: "Softmicro",
    phone: "+1 (900) 592-3841",
    subRows: [
      {
        id: 51,
        age: 23,
        name: "Bruno",
        company: "Magmina",
        phone: "+1 (813) 583-2089",
      },
    ],
  },
  {
    id: 17,
    age: 39,
    name: "Allison",
    company: "Playce",
    phone: "+1 (998) 478-3810",
    subRows: [
      {
        id: 51,
        age: 23,
        name: "Bruno",
        company: "Magmina",
        phone: "+1 (813) 583-2089",
      },
    ],
  },
  {
    id: 18,
    age: 25,
    name: "Holder",
    company: "Paragonia",
    phone: "+1 (850) 536-2708",
    subRows: [
      {
        id: 51,
        age: 23,
        name: "Bruno",
        company: "Magmina",
        phone: "+1 (813) 583-2089",
      },
    ],
  },
  {
    id: 19,
    age: 26,
    name: "Atkinson",
    company: "Scentric",
    phone: "+1 (850) 467-2761",
    subRows: [
      {
        id: 51,
        age: 23,
        name: "Bruno",
        company: "Magmina",
        phone: "+1 (813) 583-2089",
      },
    ],
  },
  {
    id: 20,
    age: 35,
    name: "Delaney",
    company: "Telpod",
    phone: "+1 (934) 468-2218",
    subRows: [
      {
        id: 51,
        age: 23,
        name: "Bruno",
        company: "Magmina",
        phone: "+1 (813) 583-2089",
      },
    ],
  },
  {
    id: 21,
    age: 20,
    name: "Myrna",
    company: "Zanity",
    phone: "+1 (953) 565-3836",
    subRows: [
      {
        id: 51,
        age: 23,
        name: "Bruno",
        company: "Magmina",
        phone: "+1 (813) 583-2089",
      },
    ],
  },
  {
    id: 22,
    age: 30,
    name: "Erna",
    company: "Techade",
    phone: "+1 (872) 574-3879",
    subRows: [
      {
        id: 51,
        age: 23,
        name: "Bruno",
        company: "Magmina",
        phone: "+1 (813) 583-2089",
      },
    ],
  },
  {
    id: 23,
    age: 36,
    name: "Fannie",
    company: "Cubix",
    phone: "+1 (843) 576-2904",
    subRows: [
      {
        id: 51,
        age: 23,
        name: "Bruno",
        company: "Magmina",
        phone: "+1 (813) 583-2089",
      },
    ],
  },
  {
    id: 24,
    age: 38,
    name: "Melody",
    company: "Talae",
    phone: "+1 (817) 424-3500",
    subRows: [
      {
        id: 51,
        age: 23,
        name: "Bruno",
        company: "Magmina",
        phone: "+1 (813) 583-2089",
      },
    ],
  },
  {
    id: 25,
    age: 27,
    name: "Letitia",
    company: "Enjola",
    phone: "+1 (857) 441-2917",
    subRows: [
      {
        id: 51,
        age: 23,
        name: "Bruno",
        company: "Magmina",
        phone: "+1 (813) 583-2089",
      },
    ],
  },
  {
    id: 26,
    age: 33,
    name: "Nina",
    company: "Eventex",
    phone: "+1 (917) 599-2793",
    subRows: [
      {
        id: 51,
        age: 23,
        name: "Bruno",
        company: "Magmina",
        phone: "+1 (813) 583-2089",
      },
    ],
  },
  {
    id: 27,
    age: 40,
    name: "Byrd",
    company: "Obones",
    phone: "+1 (846) 422-2064",
    subRows: [
      {
        id: 51,
        age: 23,
        name: "Bruno",
        company: "Magmina",
        phone: "+1 (813) 583-2089",
      },
    ],
  },
  {
    id: 28,
    age: 34,
    name: "Chen",
    company: "Dadabase",
    phone: "+1 (956) 474-3409",
    subRows: [
      {
        id: 51,
        age: 23,
        name: "Bruno",
        company: "Magmina",
        phone: "+1 (813) 583-2089",
      },
    ],
  },
  {
    id: 29,
    age: 25,
    name: "Alexandria",
    company: "Turnabout",
    phone: "+1 (964) 415-2278",
    subRows: [
      {
        id: 51,
        age: 23,
        name: "Bruno",
        company: "Magmina",
        phone: "+1 (813) 583-2089",
      },
    ],
  },
  {
    id: 30,
    age: 37,
    name: "Page",
    company: "Metroz",
    phone: "+1 (897) 541-2460",
    subRows: [
      {
        id: 51,
        age: 23,
        name: "Bruno",
        company: "Magmina",
        phone: "+1 (813) 583-2089",
      },
    ],
  },
  {
    id: 31,
    age: 24,
    name: "Clare",
    company: "Zilch",
    phone: "+1 (832) 591-3814",
    subRows: [
      {
        id: 51,
        age: 23,
        name: "Bruno",
        company: "Magmina",
        phone: "+1 (813) 583-2089",
      },
    ],
  },
  {
    id: 32,
    age: 38,
    name: "Lindsey",
    company: "Roughies",
    phone: "+1 (942) 579-2920",
    subRows: [
      {
        id: 51,
        age: 23,
        name: "Bruno",
        company: "Magmina",
        phone: "+1 (813) 583-2089",
      },
    ],
  },
  {
    id: 33,
    age: 32,
    name: "Oconnor",
    company: "Kinetica",
    phone: "+1 (899) 599-3206",
    subRows: [
      {
        id: 51,
        age: 23,
        name: "Bruno",
        company: "Magmina",
        phone: "+1 (813) 583-2089",
      },
    ],
  },
  {
    id: 34,
    age: 35,
    name: "Maldonado",
    company: "Zentime",
    phone: "+1 (955) 419-2815",
    subRows: [
      {
        id: 51,
        age: 23,
        name: "Bruno",
        company: "Magmina",
        phone: "+1 (813) 583-2089",
      },
    ],
  },
  {
    id: 35,
    age: 25,
    name: "Day",
    company: "Eargo",
    phone: "+1 (895) 555-2916",
    subRows: [
      {
        id: 51,
        age: 23,
        name: "Bruno",
        company: "Magmina",
        phone: "+1 (813) 583-2089",
      },
    ],
  },
  {
    id: 36,
    age: 20,
    name: "Collier",
    company: "Phuel",
    phone: "+1 (998) 468-2079",
    subRows: [
      {
        id: 51,
        age: 23,
        name: "Bruno",
        company: "Magmina",
        phone: "+1 (813) 583-2089",
      },
    ],
  },
  {
    id: 37,
    age: 40,
    name: "Jeannette",
    company: "Entogrok",
    phone: "+1 (904) 567-2078",
    subRows: [
      {
        id: 51,
        age: 23,
        name: "Bruno",
        company: "Magmina",
        phone: "+1 (813) 583-2089",
      },
    ],
  },
  {
    id: 38,
    age: 33,
    name: "Wallace",
    company: "Nurali",
    phone: "+1 (877) 566-3957",
    subRows: [
      {
        id: 51,
        age: 23,
        name: "Bruno",
        company: "Magmina",
        phone: "+1 (813) 583-2089",
      },
    ],
  },
  {
    id: 39,
    age: 39,
    name: "Mcfadden",
    company: "Cincyr",
    phone: "+1 (949) 405-3992",
    subRows: [
      {
        id: 51,
        age: 23,
        name: "Bruno",
        company: "Magmina",
        phone: "+1 (813) 583-2089",
      },
    ],
  },
  {
    id: 40,
    age: 21,
    name: "Chrystal",
    company: "Futurize",
    phone: "+1 (988) 458-3032",
    subRows: [
      {
        id: 51,
        age: 23,
        name: "Bruno",
        company: "Magmina",
        phone: "+1 (813) 583-2089",
      },
    ],
  },
];


interface Invoice {
  id: number | null;
  date: string | null;
  patient_id: string | null;
  total: number | null;
  vat: number | null;
  discount: number | null;
  grand_total: number | null;
  paid: number | null;
  due: number | null;
  status: string | null;
}

const invoiceList: Invoice[] = [
  { id: 1, date: "2024-01-05", patient_id: "P001", total: 500, vat: 25, discount: 50, grand_total: 475, paid: 475, due: 0, status: "Paid" },
  { id: 2, date: "2024-01-10", patient_id: "P002", total: 700, vat: 35, discount: 70, grand_total: 665, paid: 600, due: 65, status: "Partial" },
  { id: 3, date: "2024-01-15", patient_id: "P003", total: 1200, vat: 60, discount: 100, grand_total: 1160, paid: 1160, due: 0, status: "Paid" },
  { id: 4, date: "2024-01-20", patient_id: "P004", total: 450, vat: 22.5, discount: 45, grand_total: 427.5, paid: 400, due: 27.5, status: "Partial" },
  { id: 5, date: "2024-01-25", patient_id: "P005", total: 900, vat: 45, discount: 90, grand_total: 855, paid: 855, due: 0, status: "Paid" },
  { id: 6, date: "2024-02-01", patient_id: "P006", total: 300, vat: 15, discount: 30, grand_total: 285, paid: 285, due: 0, status: "Paid" },
  { id: 7, date: "2024-02-05", patient_id: "P007", total: 650, vat: 32.5, discount: 65, grand_total: 617.5, paid: 500, due: 117.5, status: "Partial" },
  { id: 8, date: "2024-02-10", patient_id: "P008", total: 750, vat: 37.5, discount: 75, grand_total: 712.5, paid: 700, due: 12.5, status: "Partial" },
  { id: 9, date: "2024-02-15", patient_id: "P009", total: 1100, vat: 55, discount: 110, grand_total: 1045, paid: 1045, due: 0, status: "Paid" },
  { id: 10, date: "2024-02-20", patient_id: "P010", total: 500, vat: 25, discount: 50, grand_total: 475, paid: 475, due: 0, status: "Paid" },
  { id: 11, date: "2024-02-25", patient_id: "P011", total: 600, vat: 30, discount: 60, grand_total: 570, paid: 570, due: 0, status: "Paid" },
  { id: 12, date: "2024-03-01", patient_id: "P012", total: 400, vat: 20, discount: 40, grand_total: 380, paid: 380, due: 0, status: "Paid" },
  { id: 13, date: "2024-03-05", patient_id: "P013", total: 750, vat: 37.5, discount: 75, grand_total: 712.5, paid: 600, due: 112.5, status: "Partial" },
  { id: 14, date: "2024-03-10", patient_id: "P014", total: 800, vat: 40, discount: 80, grand_total: 760, paid: 760, due: 0, status: "Paid" },
  { id: 15, date: "2024-03-15", patient_id: "P015", total: 550, vat: 27.5, discount: 55, grand_total: 522.5, paid: 500, due: 22.5, status: "Partial" },
];

const reportList: Invoice[] = [
  { id: 1, date: "2024-01-05", patient_id: "P001", total: 500, vat: 25, discount: 50, grand_total: 475, paid: 475, due: 0, status: "Paid" },
  { id: 2, date: "2024-01-10", patient_id: "P002", total: 700, vat: 35, discount: 70, grand_total: 665, paid: 600, due: 65, status: "Partial" },
  { id: 3, date: "2024-01-15", patient_id: "P003", total: 1200, vat: 60, discount: 100, grand_total: 1160, paid: 1160, due: 0, status: "Paid" },
  { id: 4, date: "2024-01-20", patient_id: "P004", total: 450, vat: 22.5, discount: 45, grand_total: 427.5, paid: 400, due: 27.5, status: "Partial" },
  { id: 5, date: "2024-01-25", patient_id: "P005", total: 900, vat: 45, discount: 90, grand_total: 855, paid: 855, due: 0, status: "Paid" },
  { id: 6, date: "2024-02-01", patient_id: "P006", total: 300, vat: 15, discount: 30, grand_total: 285, paid: 285, due: 0, status: "Paid" },
  { id: 7, date: "2024-02-05", patient_id: "P007", total: 650, vat: 32.5, discount: 65, grand_total: 617.5, paid: 500, due: 117.5, status: "Partial" },
  { id: 8, date: "2024-02-10", patient_id: "P008", total: 750, vat: 37.5, discount: 75, grand_total: 712.5, paid: 700, due: 12.5, status: "Partial" },
  { id: 9, date: "2024-02-15", patient_id: "P009", total: 1100, vat: 55, discount: 110, grand_total: 1045, paid: 1045, due: 0, status: "Paid" },
  { id: 10, date: "2024-02-20", patient_id: "P010", total: 500, vat: 25, discount: 50, grand_total: 475, paid: 475, due: 0, status: "Paid" },
  { id: 11, date: "2024-02-25", patient_id: "P011", total: 600, vat: 30, discount: 60, grand_total: 570, paid: 570, due: 0, status: "Paid" },
  { id: 12, date: "2024-03-01", patient_id: "P012", total: 400, vat: 20, discount: 40, grand_total: 380, paid: 380, due: 0, status: "Paid" },
  { id: 13, date: "2024-03-05", patient_id: "P013", total: 750, vat: 37.5, discount: 75, grand_total: 712.5, paid: 600, due: 112.5, status: "Partial" },
  { id: 14, date: "2024-03-10", patient_id: "P014", total: 800, vat: 40, discount: 80, grand_total: 760, paid: 760, due: 0, status: "Paid" },
  { id: null, date: null, patient_id: null, total: 150015710.00, vat: 915.00, discount: 105000450.00, grand_total: 45016175.00, paid: 3725.00, due: 45012450.00, status: null },
];


interface Payment {
  id: string;
  date: string;
  account_name: string;
  pay_to: string;
  description: string;
  amount: number;
  status: string;
}

const payments: Payment[] = [
  {
    id: "txn001",
    date: "2024-06-15",
    account_name: "Checking Account",
    pay_to: "Rent Payment",
    description: "Monthly rent payment for June",
    amount: 1200.00,
    status: "completed"
  },
  {
    id: "txn002",
    date: "2024-06-14",
    account_name: "Credit Card",
    pay_to: "Grocery Store",
    description: "Weekly grocery shopping",
    amount: 150.75,
    status: "completed"
  },
  {
    id: "txn003",
    date: "2024-06-13",
    account_name: "Savings Account",
    pay_to: "Emergency Fund",
    description: "Transfer to emergency fund",
    amount: 500.00,
    status: "pending"
  },
  {
    id: "txn004",
    date: "2024-06-12",
    account_name: "Checking Account",
    pay_to: "Utility Bill",
    description: "Electricity bill payment",
    amount: 80.32,
    status: "completed"
  },
  {
    id: "txn005",
    date: "2024-06-11",
    account_name: "Investment Account",
    pay_to: "Brokerage",
    description: "Stock purchase",
    amount: 1000.00,
    status: "completed"
  }
];

interface DebitReport {
  id: number | null;
  date: string | null;
  patient_id: string | null;
  account_name: string | null;
  description: string | null;
  quantity: number | null;
  price: number | null;
  total: number | null;
}

const debitReports: DebitReport[] = [
  {
    id: 1,
    date: "2024-06-15",
    patient_id: "PT001",
    account_name: "Alice Smith",
    description: "Consultation fee",
    quantity: 1,
    price: 150.00,
    total: 150.00
  },
  {
    id: 2,
    date: "2024-06-16",
    patient_id: "PT002",
    account_name: "Bob Johnson",
    description: "Blood test",
    quantity: 2,
    price: 75.00,
    total: 150.00
  },
  {
    id: 3,
    date: "2024-06-17",
    patient_id: "PT003",
    account_name: "Charlie Brown",
    description: "X-ray imaging",
    quantity: 1,
    price: 200.00,
    total: 200.00
  },
  {
    id: 4,
    date: "2024-06-18",
    patient_id: "PT004",
    account_name: "Diana Prince",
    description: "Physical therapy session",
    quantity: 5,
    price: 50.00,
    total: 250.00
  },
  {
    id: 5,
    date: "2024-06-19",
    patient_id: "PT005",
    account_name: "Evan Rogers",
    description: "Surgery fee",
    quantity: 1,
    price: 3000.00,
    total: 3000.00
  },
  {
    id: 6,
    date: "2024-06-20",
    patient_id: "PT006",
    account_name: "Fiona Clarke",
    description: "Prescription medication",
    quantity: 10,
    price: 20.00,
    total: 200.00
  },
  {
    id: 7,
    date: "2024-06-21",
    patient_id: "PT007",
    account_name: "George King",
    description: "MRI scan",
    quantity: 1,
    price: 800.00,
    total: 800.00
  },
  {
    id: 8,
    date: "2024-06-22",
    patient_id: "PT008",
    account_name: "Hannah White",
    description: "Dental cleaning",
    quantity: 1,
    price: 120.00,
    total: 120.00
  },
  {
    id: 9,
    date: "2024-06-23",
    patient_id: "PT009",
    account_name: "Ian Black",
    description: "Vision test",
    quantity: 1,
    price: 60.00,
    total: 60.00
  },
  {
    id: 10,
    date: "2024-06-24",
    patient_id: "PT010",
    account_name: "Jasmine Green",
    description: "Laboratory analysis",
    quantity: 3,
    price: 30.00,
    total: 90.00
  },
  {
    id: 11,
    date: "2024-06-25",
    patient_id: "PT011",
    account_name: "Kyle Blue",
    description: "Ultrasound",
    quantity: 1,
    price: 250.00,
    total: 250.00
  },
  {
    id: 12,
    date: "2024-06-26",
    patient_id: "PT012",
    account_name: "Lily Brown",
    description: "Vaccination",
    quantity: 1,
    price: 50.00,
    total: 50.00
  },
  {
    id: null,
    date: null,
    patient_id: null,
    account_name: null,
    description: null,
    quantity: 14.00,
    price: 5550.00,
    total: 5902.00
  },
];

interface CreditReport {
  id: number | null;
  date: string | null;
  account_name: string | null;
  pay_to: string | null;
  description: string | null;
  amount: number | null;
}

const creditReports: CreditReport[] = [
  {
    id: 1,
    date: "2024-06-15",
    account_name: "Alice Smith",
    pay_to: "XYZ Medical Center",
    description: "Insurance reimbursement",
    amount: 100.00
  },
  {
    id: 2,
    date: "2024-06-16",
    account_name: "Bob Johnson",
    pay_to: "Health Insurance Co.",
    description: "Claim settlement",
    amount: 500.00
  },
  {
    id: 3,
    date: "2024-06-17",
    account_name: "Charlie Brown",
    pay_to: "Health Savings Account",
    description: "Contribution",
    amount: 200.00
  },
  {
    id: 4,
    date: "2024-06-18",
    account_name: "Diana Prince",
    pay_to: "Medical Services Inc.",
    description: "Refund",
    amount: 50.00
  },
  {
    id: 5,
    date: "2024-06-19",
    account_name: "Evan Rogers",
    pay_to: "Healthcare Provider",
    description: "Reimbursement",
    amount: 300.00
  },
  {
    id: null,
    date: null,
    account_name: null,
    pay_to: null,
    description: null,
    amount: 10000.00
  }
];


export {
  records,
  accountsArray, expandableRecords, invoiceList, payments, reportList,
  debitReports, creditReports
};
