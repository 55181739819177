interface Supplier {
  id: number;       
  name: string;      
  email: string;     
  phone: string;    
  total: number;     
  paid: number;      
  due: number;       
}

const suppliers: Supplier[] = [
  {
      id: 1,
      name: "John Doe",
      email: "johndoe@example.com",
      phone: "+1234567890",
      total: 500.00,
      paid: 300.00,
      due: 200.00
  },
  {
      id: 2,
      name: "Jane Smith",
      email: "janesmith@example.com",
      phone: "+0987654321",
      total: 1200.00,
      paid: 1200.00,
      due: 0.00
  },
  {
      id: 3,
      name: "Alice Johnson",
      email: "alicej@example.com",
      phone: "+1122334455",
      total: 750.00,
      paid: 500.00,
      due: 250.00
  },
  {
      id: 4,
      name: "Bob Brown",
      email: "bobb@example.com",
      phone: "+1223344556",
      total: 400.00,
      paid: 400.00,
      due: 0.00
  },
  {
      id: 5,
      name: "Carol White",
      email: "carolw@example.com",
      phone: "+1445566778",
      total: 950.00,
      paid: 650.00,
      due: 300.00
  }
];

interface Product {
  id: number;         
  name: string;       
  sku: string;      
  initial: number;    
  purchases: number;  
  in: number;        
  out: number;       
  consumption: number; 
  stock: number;      
}

const products: Product[] = [
  {
      id: 1,
      name: "PCR Reagent Kit",
      sku: "PCR-001",
      initial: 500,
      purchases: 300,
      in: 50,
      out: 400,
      consumption: 200,
      stock: 250
  },
  {
      id: 2,
      name: "Microcentrifuge Tubes",
      sku: "MCT-002",
      initial: 1000,
      purchases: 500,
      in: 100,
      out: 800,
      consumption: 300,
      stock: 500
  },
  {
      id: 3,
      name: "DNA Extraction Kit",
      sku: "DNA-003",
      initial: 200,
      purchases: 150,
      in: 30,
      out: 180,
      consumption: 100,
      stock: 100
  },
  {
      id: 4,
      name: "Serological Pipettes",
      sku: "PIP-004",
      initial: 600,
      purchases: 400,
      in: 50,
      out: 700,
      consumption: 250,
      stock: 100
  },
  {
      id: 5,
      name: "Disposable Gloves",
      sku: "GLV-005",
      initial: 2000,
      purchases: 1000,
      in: 200,
      out: 1500,
      consumption: 700,
      stock: 1000
  },
  {
      id: 6,
      name: "Lab Notebooks",
      sku: "LNB-006",
      initial: 300,
      purchases: 100,
      in: 20,
      out: 120,
      consumption: 50,
      stock: 250
  },
  {
      id: 7,
      name: "Ethanol Solution (70%)",
      sku: "ETH-007",
      initial: 800,
      purchases: 400,
      in: 150,
      out: 600,
      consumption: 300,
      stock: 450
  },
  {
      id: 8,
      name: "Glass Beakers (500ml)",
      sku: "GBK-008",
      initial: 100,
      purchases: 50,
      in: 10,
      out: 70,
      consumption: 20,
      stock: 70
  },
  {
      id: 9,
      name: "Centrifuge Tubes (15ml)",
      sku: "CTB-009",
      initial: 500,
      purchases: 200,
      in: 30,
      out: 300,
      consumption: 150,
      stock: 280
  },
  {
      id: 10,
      name: "pH Test Strips",
      sku: "PHS-010",
      initial: 1000,
      purchases: 400,
      in: 50,
      out: 500,
      consumption: 300,
      stock: 650
  }
];

interface Purchase {
  id: number;       
  date: string;        
  branch: string;      
  supplier: string;    
  total: number;       
  paid: number;        
  due: number;         
}


const purchases: Purchase[] = [
  {
      id: 1,
      date: "2024-01-05",
      branch: "New York",
      supplier: "BioSupplies Inc.",
      total: 1200.00,
      paid: 800.00,
      due: 400.00
  },
  {
      id: 2,
      date: "2024-01-10",
      branch: "San Francisco",
      supplier: "Lab Equip Ltd.",
      total: 2500.00,
      paid: 2500.00,
      due: 0.00
  },
  {
      id: 3,
      date: "2024-01-15",
      branch: "Los Angeles",
      supplier: "Chemicals Corp.",
      total: 1800.00,
      paid: 1000.00,
      due: 800.00
  },
  {
      id: 4,
      date: "2024-01-20",
      branch: "Chicago",
      supplier: "Medical Supplies Co.",
      total: 1500.00,
      paid: 1500.00,
      due: 0.00
  },
  {
      id: 5,
      date: "2024-02-01",
      branch: "Miami",
      supplier: "Reagents & More",
      total: 900.00,
      paid: 500.00,
      due: 400.00
  },
  {
      id: 6,
      date: "2024-02-05",
      branch: "Houston",
      supplier: "Lab Instruments Inc.",
      total: 2100.00,
      paid: 1100.00,
      due: 1000.00
  },
  {
      id: 7,
      date: "2024-02-10",
      branch: "Seattle",
      supplier: "Science Supplies LLC",
      total: 3200.00,
      paid: 3200.00,
      due: 0.00
  },
  {
      id: 8,
      date: "2024-02-15",
      branch: "Denver",
      supplier: "EquipTech Ltd.",
      total: 2800.00,
      paid: 2000.00,
      due: 800.00
  },
  {
      id: 9,
      date: "2024-02-20",
      branch: "Boston",
      supplier: "Biotech Solutions",
      total: 1300.00,
      paid: 700.00,
      due: 600.00
  },
  {
      id: 10,
      date: "2024-02-25",
      branch: "Dallas",
      supplier: "Precision Lab Supply",
      total: 1000.00,
      paid: 500.00,
      due: 500.00
  }
];

interface Adjustment {
  id: number;       
  date: string;      
  branch: string;  
}

const adjustments: Adjustment[] = [
  {
      id: 1,
      date: "2024-03-01",
      branch: "New York"
  },
  {
      id: 2,
      date: "2024-03-05",
      branch: "San Francisco"
  },
  {
      id: 3,
      date: "2024-03-10",
      branch: "Los Angeles"
  },
  {
      id: 4,
      date: "2024-03-15",
      branch: "Chicago"
  },
  {
      id: 5,
      date: "2024-03-20",
      branch: "Miami"
  },
  {
      id: 6,
      date: "2024-03-25",
      branch: "Houston"
  },
  {
      id: 7,
      date: "2024-03-30",
      branch: "Seattle"
  },
  {
      id: 8,
      date: "2024-04-01",
      branch: "Denver"
  },
  {
      id: 9,
      date: "2024-04-05",
      branch: "Boston"
  },
  {
      id: 10,
      date: "2024-04-10",
      branch: "Dallas"
  }
];

interface Transfer {
  id: number;        
  date: string;     
  fromBranch: string;
  toBranch: string; 
}

const transfers: Transfer[] = [
  {
      id: 1,
      date: "2024-03-01",
      fromBranch: "New York",
      toBranch: "San Francisco"
  },
  {
      id: 2,
      date: "2024-03-05",
      fromBranch: "San Francisco",
      toBranch: "Los Angeles"
  },
  {
      id: 3,
      date: "2024-03-10",
      fromBranch: "Chicago",
      toBranch: "New York"
  },
  {
      id: 4,
      date: "2024-03-15",
      fromBranch: "Miami",
      toBranch: "Chicago"
  },
  {
      id: 5,
      date: "2024-03-20",
      fromBranch: "Houston",
      toBranch: "Dallas"
  },
  {
      id: 6,
      date: "2024-03-25",
      fromBranch: "Seattle",
      toBranch: "Denver"
  },
  {
      id: 7,
      date: "2024-03-30",
      fromBranch: "Boston",
      toBranch: "Houston"
  },
  {
      id: 8,
      date: "2024-04-01",
      fromBranch: "Los Angeles",
      toBranch: "Seattle"
  },
  {
      id: 9,
      date: "2024-04-05",
      fromBranch: "Denver",
      toBranch: "Boston"
  },
  {
      id: 10,
      date: "2024-04-10",
      fromBranch: "Dallas",
      toBranch: "Miami"
  }
];

interface Branch {
    id: number;     
    name: string;  
}
  

const branches: Branch[] = [
    { id: 1, name: "Main Branch" },
    { id: 2, name: "Downtown Branch" },
    { id: 3, name: "West End Branch" },
    { id: 4, name: "Northside Branch" },
    { id: 5, name: "Southside Branch" }
  ];

export { suppliers,products,purchases,adjustments,transfers,branches};
