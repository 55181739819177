import React from "react";
import { Row, Col, Card, Dropdown, ButtonGroup, Form, Button } from "react-bootstrap";
import { Cell, Row as TableRow } from 'react-table'; // Import Cell and Row types
import { accountsArray as data } from "../data";
import { Link } from "react-router-dom";
import { Edit, Trash } from 'feather-icons-react';
import PageTitle from "../../PageTitle";
import Table from "../../Table";



const AddAccount = () => {
  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Tables", path: "/features/tables/advanced" },
          {
            label: "Advanced Tables",
            path: "/features/tables/advanced",
            active: true,
          },
        ]}
        title={"Add Account"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <h4 className="mb-3 header-title mt-0">Add Account</h4>

              <Form>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={2} htmlFor="name">Account Name</Form.Label>
                  <Col lg={6}>
                    <Form.Control
                      name="name"
                      id="name"
                      placeholder="Account Name"
                    />
                  </Col>

                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={2} htmlFor="type" aria-required>Type</Form.Label>
                  <Col lg={6}>
                    <Form.Select name="type"
                      id="type">
                      <option value="debit">Debit</option>
                      <option value="credit">Credit</option>
                    </Form.Select>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={2} htmlFor="description">Description</Form.Label>
                  <Col lg={6}><Form.Control
                    as="textarea" rows={5}
                    name="description"
                    id="description"
                    placeholder="Description"
                  />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={2}>
                    Status
                  </Form.Label>
                  <Col lg={7}>
                    <Row>
                      <Col md={"auto"}>
                        <Form.Check
                          type="radio"
                          className="mb-1"
                          id="active"
                          name="status"
                          label="Active"
                          defaultChecked
                        />
                      </Col>
                      <Col md={"auto"}>
                        <Form.Check
                          type="radio"
                          className="mb-1"
                          id="inactive"
                          name="status"
                          label="Inactive"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Form.Group>


                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>

    </>
  );
};

export default AddAccount;
