import React from "react";
import { Row, Col, Card, Dropdown, ButtonGroup, Form, Button } from "react-bootstrap";
import { Cell, Row as TableRow } from 'react-table';
import { Link } from "react-router-dom";
import { Edit, Trash } from 'feather-icons-react';
import PageTitle from "../../PageTitle";




const AddSupplier = () => {
  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Tables", path: "/features/tables/advanced" },
          {
            label: "Advanced Tables",
            path: "/features/tables/advanced",
            active: true,
          },
        ]}
        title={"Add Supplier"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <h4 className="mb-3 header-title mt-0">Add Supplier</h4>

              <Form>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={2} htmlFor="name">Name</Form.Label>
                  <Col lg={6}>
                    <Form.Control
                      name="name"
                      id="name"
                      placeholder="Name"
                    />
                  </Col>

                </Form.Group>
                
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={2} htmlFor="email">Email</Form.Label>
                  <Col lg={6}>
                    <Form.Control
                      name="email"
                      type="email"
                      id="email"
                      placeholder="Email"
                    />
                  </Col>

                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={2} htmlFor="phone">Phone</Form.Label>
                  <Col lg={6}>
                    <Form.Control
                      name="phone"
                      id="phone"
                      placeholder="Phone"
                    />
                  </Col>

                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={2} htmlFor="address">Address</Form.Label>
                  <Col lg={6}><Form.Control
                    as="textarea" rows={5}
                    name="address"
                    id="address"
                    placeholder="Address"
                  />
                  </Col>
                </Form.Group>

                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>

    </>
  );
};

export default AddSupplier;
