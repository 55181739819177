import React, { useState } from "react";
import { Card, Form, Button, Row, Col } from "react-bootstrap";
import { Plus, Minus } from 'feather-icons-react';
import PageTitle from "../../PageTitle";

// Define the types for product rows
interface ProductRow {
    id: number;
    product: string;
    quantity: number;
    type: string;
    note: string;
}

const AddAdjustment = () => {
    // Initial state for the form and product rows
    const [productRows, setProductRows] = useState<ProductRow[]>([{ id: 1, product: "", quantity: 0, type: "", note: "" }]);
    const [date, setDate] = useState("");
    const [branch, setBranch] = useState("");

    // Handle adding a new product row
    const handleAddProduct = () => {
        const newRowId = productRows.length + 1;
        setProductRows([...productRows, { id: newRowId, product: "", quantity: 0, type: "", note: "" }]);
    };

    // Handle removing a product row
    const handleRemoveProduct = (idToRemove: number) => {
        const updatedRows = productRows.filter((row) => row.id !== idToRemove);
        setProductRows(updatedRows);
    };

    // Handle changes in product row inputs
    const handleProductChange = (id: number, field: keyof ProductRow, value: any) => {
        const updatedRows = productRows.map((row) => {
            if (row.id === id) {
                return { ...row, [field]: value };
            }
            return row;
        });
        setProductRows(updatedRows);
    };

    // Handle form submission
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.log("Form submitted:", { date, branch, productRows });
        // Add your submit logic here (e.g., send to server)
    };

    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    { label: "Tables", path: "/features/tables/advanced" },
                    {
                        label: "Advanced Tables",
                        path: "/features/tables/advanced",
                        active: true,
                    },
                ]}
                title={"Add Adjustments"}
            />
            <Card>
                <Card.Body>

                    <h4 className="mb-3 header-title mt-0">Add Adjustments</h4>


                    <Form onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Col lg={3}>
                                <Form.Group>
                                    <Form.Label>Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="date"
                                        value={date}
                                        onChange={(e) => setDate(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={3}>
                                <Form.Group>
                                    <Form.Label>Branch</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="branch_id"
                                        value={branch}
                                        onChange={(e) => setBranch(e.target.value)}
                                        required
                                    >
                                        <option value="" disabled>Select branch</option>
                                        <option value="1">Branch 1</option>
                                        <option value="2">Branch 2</option>
                                        <option value="3">Branch 3</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card className="card-primary card-outline">
                                    <Card.Header>
                                        <Row className="mb-1">
                                            <Col className="d-flex justify-content-between align-items-center">
                                                <h4 className="mb-0 header-title mt-0">Products</h4>
                                                <Button
                                                    type="button"
                                                    className="btn btn-primary add_product"
                                                    onClick={handleAddProduct}
                                                >
                                                    <Plus />
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Card.Header>
                                    <Card.Body className="p-0">
                                        <table className="table table-bordered" id="products_table">
                                            <thead>
                                                <tr>
                                                    <th>Product</th>
                                                    <th>Quantity</th>
                                                    <th>Type</th>
                                                    <th>Note</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {productRows.map((row) => (
                                                    <tr key={row.id}>
                                                        <td>
                                                            <Form.Group>
                                                                <Form.Control
                                                                    as="select"
                                                                    name={`products[${row.id}][id]`}
                                                                    value={row.product}
                                                                    onChange={(e) => handleProductChange(row.id, 'product', e.target.value)}
                                                                    required
                                                                >
                                                                    <option value="" disabled>Select product</option>
                                                                    <option value="1">Product 1</option>
                                                                    <option value="2">Product 2</option>
                                                                    <option value="3">Product 3</option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </td>
                                                        <td>
                                                            <Form.Group>
                                                                <Form.Control
                                                                    type="number"
                                                                    name={`products[${row.id}][quantity]`}
                                                                    value={row.quantity}
                                                                    min="0"
                                                                    onChange={(e) => handleProductChange(row.id, 'quantity', parseInt(e.target.value))}
                                                                    required
                                                                />
                                                            </Form.Group>
                                                        </td>
                                                        <td>
                                                            <Form.Group>
                                                                <Form.Control
                                                                    as="select"
                                                                    name={`products[${row.id}][type]`}
                                                                    value={row.type}
                                                                    onChange={(e) => handleProductChange(row.id, 'type', e.target.value)}
                                                                    required
                                                                >
                                                                    <option value="" disabled>Type</option>
                                                                    <option value="1">In</option>
                                                                    <option value="2">Out</option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </td>
                                                        <td>
                                                            <Form.Group>
                                                                <Form.Control
                                                                    as="textarea"
                                                                    name={`products[${row.id}][note]`}
                                                                    value={row.note}
                                                                    rows={1}
                                                                    placeholder="Note"
                                                                    onChange={(e) => handleProductChange(row.id, 'note', e.target.value)}
                                                                />
                                                            </Form.Group>
                                                        </td>
                                                        <td>
                                                            <Button
                                                                type="button"
                                                                className="btn btn-danger"
                                                                onClick={() => handleRemoveProduct(row.id)}
                                                            >
                                                                <Minus />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Button type="submit" className="btn btn-primary">
                            <i className="fa fa-check"></i> Save
                        </Button>
                    </Form>

                </Card.Body>
            </Card>

        </>
    );
};

export default AddAdjustment;
