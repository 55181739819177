import React from "react";
import { Row, Col, Card, Dropdown, ButtonGroup } from "react-bootstrap";
import { Cell, Row as TableRow } from 'react-table'; // Import Cell and Row types
import PageTitle from "../../PageTitle";
import Table from "../../Table";
import { reportList as data } from "../data";
import { Link } from "react-router-dom";
import { Edit, Trash } from 'feather-icons-react';

const columns = [
  {
    Header: "ID",
    accessor: "id",
    sort: true,
  },
  {
    Header: "Patient ID",
    accessor: "patient_id",
    sort: true,
  },
  {
    Header: "Total",
    accessor: "total",
    sort: false,
  },
  {
    Header: "Vat",
    accessor: "vat",
    sort: true,
  },
  {
    Header: "Discount",
    accessor: "discount",
    sort: false,
  },
  {
    Header: "Grand Total",
    accessor: "grand_total",
    sort: false,
  },
  {
    Header: "Paid",
    accessor: "paid",
    sort: false,
  },
  {
    Header: "Due",
    accessor: "due",
    sort: false,
  }
 
];

const sizePerPageList = [
  {
    text: "5",
    value: 5,
  },
  {
    text: "10",
    value: 10,
  },
  {
    text: "25",
    value: 25,
  },
  {
    text: "All",
    value: data.length,
  },
];

const AccountReport = () => {
  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Tables", path: "/features/tables/advanced" },
          {
            label: "Advanced Tables",
            path: "/features/tables/advanced",
            active: true,
          },
        ]}
        title={"Account Report"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <div className="page-title-box">
                <h4 className="page-title">
                Account Report
                </h4>
              </div>

              <Table
                columns={columns}
                data={data}
                pageSize={5}
                sizePerPageList={sizePerPageList}
                isSortable={true}
                pagination={false}
                isSearchable={true}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

    </>
  );
};

export default AccountReport;
