import React from "react";
import { Row, Col, Card, Dropdown, ButtonGroup, Form, Button } from "react-bootstrap";
import { Cell, Row as TableRow } from 'react-table';
import { Link } from "react-router-dom";
import { Edit, Trash } from 'feather-icons-react';
import PageTitle from "../../PageTitle";
import Table from "../../Table";
import { branches as data } from "../data";

const columns = [
  {
    Header: "Name",
    accessor: "name",
    sort: false,
    width: "60%",
  },
  {
    Header: "Initial Quantity", accessor: "initial_quantity", sort: false, Cell: ({ row }: { row: TableRow<any> }) => (
      <Form.Control type="number" name="quantity"
        id="quantity" defaultValue={0}>
      </Form.Control>
    ),
  },
  {
    Header: "Stock Alert", accessor: "stock_alert", sort: false, Cell: ({ row }: { row: TableRow<any> }) => (
      <Form.Control type="number" name="stock_alert"
        id="stock_alert" defaultValue={0}>
      </Form.Control>
    ),
  },
]

const AddProduct = () => {
  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Tables", path: "/features/tables/advanced" },
          {
            label: "Advanced Tables",
            path: "/features/tables/advanced",
            active: true,
          },
        ]}
        title={"Add Product"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <h4 className="mb-3 header-title mt-0">Add Product</h4>


              <Form>

                <Row>
                  <Col md={"6"} className="mb-3">
                    <Form.Group>
                      <Form.Label htmlFor="name">Name</Form.Label>
                      <Form.Control
                        type="name"
                        name="name"
                        id="name"
                        placeholder="Name"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={"6"} className="mb-3">
                    <Form.Group>
                      <Form.Label htmlFor="sku">SKU</Form.Label>
                      <Form.Control
                        type="text"
                        name="sku"
                        id="sku"
                        placeholder="SKU"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group className="mb-3">
                  <Form.Label htmlFor="description">Description</Form.Label>
                  <Form.Control
                    as="textarea" rows={3}
                    name="description"
                    id="description"
                    placeholder="Description"
                  />
                </Form.Group>


                <Card>
                  <Card.Body>
                    <h4>Branches</h4>
                    <Table
                      columns={columns}
                      data={data}
                      pageSize={5}
                      sizePerPageList={sizePerPageList}
                      isSortable={true}
                      pagination={false}
                      isSearchable={false}
                    />
                  </Card.Body>
                </Card>

                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>

    </>
  );
};

const sizePerPageList = [
  {
    text: "5",
    value: 5,
  },
  {
    text: "10",
    value: 10,
  },
  {
    text: "25",
    value: 25,
  },
  {
    text: "All",
    value: data.length,
  },
];

export default AddProduct;
