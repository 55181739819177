import React from "react";
import { Row, Col, Card, Dropdown, ButtonGroup } from "react-bootstrap";
import { Cell, Row as TableRow } from 'react-table'; // Import Cell and Row types
import PageTitle from "../../PageTitle";
import Table from "../../Table";
import { products as data } from "../data";
import { Link } from "react-router-dom";
import { Edit, Trash } from 'feather-icons-react';

const columns = [
  {
    Header: "ID",
    accessor: "id",
    sort: true,
  },
  {
    Header: "Name",
    accessor: "name",
    sort: true,
  },
  {
    Header: "SKU",
    accessor: "sku",
    sort: false,
  },
  {
    Header: "Initial",
    accessor: "initial",
    sort: true,
  },
  {
    Header: "Purchases",
    accessor: "purchases",
    sort: false,
  },
  {
    Header: "In",
    accessor: "in",
    sort: false,
  },
  {
    Header: "Out",
    accessor: "out",
    sort: false,
  },
  {
    Header: "Consumption",
    accessor: "consumption",
    sort: false,
  },
  {
    Header: "Stock",
    accessor: "stock",
    sort: false,
  },
  {
    Header: "Action", accessor: "action", sort: false, Cell: ({ row }: { row: TableRow<any> }) => (
      <Dropdown>
        <Dropdown.Toggle variant="primary" id="dropdown-action" style={{ borderRadius: "50%", width: "30px", height: "30px", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <i className="bi-gear-wide-connected"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item as={Link} to={`/lab/edit-invoice/${row.original.id}`}><Edit /> Edit</Dropdown.Item>
          <Dropdown.Item><Trash />Delete</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    ),
  },
];

const sizePerPageList = [
  {
    text: "5",
    value: 5,
  },
  {
    text: "10",
    value: 10,
  },
  {
    text: "25",
    value: 25,
  },
  {
    text: "All",
    value: data.length,
  },
];

const ProductList = () => {
  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Tables", path: "/features/tables/advanced" },
          {
            label: "Advanced Tables",
            path: "/features/tables/advanced",
            active: true,
          },
        ]}
        title={"Product List"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>

            <div className="page-title-box">
                  <h4 className="page-title">
                    Product List
                  </h4>
                  <div className="page-title-right">
                    <ButtonGroup className="ms-2 d-none d-sm-inline-block me-1">
                      <Link to="/inventory/products/create" className="btn btn-primary">
                        <i className="uil uil-plus me-1"></i>Add Product
                      </Link>
                    </ButtonGroup>
                  </div>
                </div>

              <Table
                columns={columns}
                data={data}
                pageSize={5}
                sizePerPageList={sizePerPageList}
                isSortable={true}
                pagination={true}
                isSearchable={true}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

    </>
  );
};

export default ProductList;
